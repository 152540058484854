<template>
  <v-slide-item>
    <div class="pl-2" style="width: 270px; height: 500px; shaped">
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <v-select
                v-model="fazenda"
                :items="fazendas"
                item-value="id"
                item-text="name"
                single-line
                :label="$t('farm.farm')"
                hide-details
                :disabled="selectConfig.fazenda.load"
                :loading="selectConfig.fazenda.load"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="cultivar"
                :items="cultivares"
                item-value="id"
                item-text="cultivar"
                single-line
                :label="$t('cultivar.cultivar')"
                hide-details
                :disabled="selectConfig.cultivar.load || !this.fazenda"
                :loading="selectConfig.cultivar.load"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="programa"
                :items="programas"
                item-value="id"
                item-text="nome"
                single-line
                :label="$t('programa.programa')"
                hide-details
                :disabled="selectConfig.programa.load || !this.cultivar"
                :loading="selectConfig.programa.load"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-btn
            block
            class="primary"
            @click="carregaProgramaSelecionado()"
            :disabled="isAddButtonDisabled"
          >
            {{ $t('adicionar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-slide-item>
</template>

<script>
export default {
  name: 'ComparisonSearch',

  props: {
    selectConfig: { type: Object, required: true },
    fazendas: { type: Array, required: true },
    cultivares: { type: Array, required: true },
    programas: { type: Array, required: true },
  },

  data() {
    return {
      fazenda: null,
      cultivar: null,
      programa: null,
    }
  },

  mounted() {
    this.$emit('listaFazendas')
  },

  watch: {
    fazenda(val) {
      this.cultivar = null
      this.programa = null
      if (val) this.$emit('listaCultivares', val)
    },
    cultivar(val) {
      this.programa = null
      if (val) this.$emit('listaProgramas', val)
    },
  },

  computed: {
    isAddButtonDisabled() {
      return !this.fazenda || !this.cultivar || !this.programa
    },
  },

  methods: {
    carregaProgramaSelecionado() {
      this.$emit(
        'carregaProgramaSelecionado',
        this.fazenda,
        this.cultivar,
        this.programa
      )
      this.fazenda = null
    },
  },
}
</script>
